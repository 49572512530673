import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useTranslation } from './TranslationContext';


const Savecart = () => {
  const [cards, setCards] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const { translate } = useTranslation();

  const increaseQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };


  const handleAddToCart = async (cart_id,id,price1,qty) => {
    try {
      const product_id = id; 
      const user_id = localStorage.getItem('admin_id'); 
      const quantity = qty;
      const price = price1;
  
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addtocart', {
        product_id,
        user_id,
        quantity,
        price,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });
      const check_res = response.data.status;
  
      if (check_res) {
        handlesaveCartremove1(cart_id)
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };


  // const handlesaveCartremove = async (id) => {

  //   try {
  //     const product_id = id; 
  //     const user_id = localStorage.getItem('admin_id'); 
  //     const api_url = localStorage.getItem('api_url');
  //     const response = await axios.get(api_url + '/removesavecart/'+product_id)
  //     const check_res = response.data.status;
  //     if (check_res) {
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.error("Error adding to cart:", error);
  //   }

  // };

  const handlesaveCartremove = async (id,products_id,quant) => {

    try {

      //get product details
      const api_url = localStorage.getItem('api_url');
      const product_id = products_id;
      const response2 = await axios.get(api_url + '/admingetProduct/'+product_id)
      const res2 = response2.data.product;
    
      // update quantity
      const remain_qty = res2.qty + quant;
      const response1 = await axios.post(api_url + '/updateproductquantity', {
        product_id,
        remain_qty,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });

      const check_res2 = response1.data.status;
       
      if(check_res2){

        const productss_id = id; 
        const user_id = localStorage.getItem('admin_id'); 
        const response = await axios.get(api_url + '/removesavecart/'+productss_id)
        const check_res = response.data.status;
        if (check_res) {
          window.location.reload();
        }
      }

     
    } catch (error) {
      console.error("Error adding to cart:", error);
    }

  };


  const handlesaveCartremove1 = async (cart_id) => {
    const api_url = localStorage.getItem('api_url');
    const response = await axios.get(api_url + '/removesavecart/'+cart_id)
    const check_res = response.data.status;
    if (check_res) {
      window.location.reload();
    }
  }

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getusersavecarts/'+user_id);
        setCards(response.data.savecartItems);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div>
        <h2 className="save_for">{translate('save_for_later')} ({cards.length})</h2>
      
        <hr></hr>
      {cards.map((product) => (
        <div>
        <div className="order-card" key={product.id}>
          <div className="product-info">
            <img className="product-image" src={product.product_image} alt={product.product_name} />
                    <div className="product-details">
                        <p className="product-name"><b>{product.product_name}</b></p>
                        <p>{product.brand_name}</p>
                        <p className="product-price">
                          {product.price_final < product.price ? (
                          <>
                          <span className='original-price'>₹{product.price}</span>
                          <span className='final-price'>₹{product.price_final}</span>
                          </>
                          ) : (
                          <span className='final-price'>₹{product.price}</span>
                          )}
                          </p>
                        <div className="quantity_details">
                            <div className="quantity-controls">
                                    <button className="quantity-button" onClick={decreaseQuantity}>-</button>
                                    <span className="quantity">{product.quantity}</span>
                                    <button className="quantity-button" onClick={increaseQuantity}>+</button>
                            </div>

                              <button className="product_save" onClick={() => handleAddToCart(product.cart_id,product.product_id,product.price,product.quantity)}>{translate('moveto')}</button>
                              <button className="product_remove" onClick={() => handlesaveCartremove(product.cart_id,product.product_id,product.quantity)}> {translate('remove')} </button>
                            </div>
                    </div>
                    <div className="del_days">
                        <p></p>
                    </div>
          </div>
        </div>
        <hr></hr>
         
        </div>
        
      ))}

{cards.length === 0 && (
        <p className="emptycart">Your cart is empty</p>
      )}
      
      <br></br>
      <p> </p>
    </div>
    
  );
};

export default Savecart;
